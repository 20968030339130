import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import {Observable, retry} from "rxjs";
import {
  ATTACHMENT_API,
  ATTACHMENT_DELETE_SUPPLIERS,
  ATTACHMENT_UPLOAD_API,
  ATTACHMENT_UPLOAD_API_TECHNICAL_PROPOSALS,
  ATTACHMENT_UPLOAD_MANDADORY_DOC,
  ATTACHMENT_UPLOAD_SUPPLIERS
} from "../../modules/projects/constants/apiEndpointsConstants";
import {AttachmentsModel, AttachmentsAndFoldersModel} from "../models/attachments.model";
import {CommentRequestDto} from "../../modules/documents/documents-list/comment-modal/CommentRequestDto";
import { NgrxAiUtilsService } from 'src/app/modules/chat-ia/service/ngrx-ai/ngrx-ai-utils.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(
    private readonly http: HttpClient,
    private ngrxAiUtilsService: NgrxAiUtilsService
  ) { }
  queryParams = "";

  getAttachmentsByEntityAndEntityId(entityId: number | undefined, entityName: string): Observable<AttachmentsModel[]> {
    let response = this.http.get<AttachmentsModel[]>(`${ATTACHMENT_API}/${entityId}/${entityName}`)
    response.subscribe(attachments => {
      const mappedAttachments: any[] = this.ngrxAiUtilsService.buildAttachmentAdditionalByStore(attachments);
      this.ngrxAiUtilsService.pushAttachmentListOnStore(mappedAttachments);
    });
    return response;
  }

  getAllAttachmentsForProject(projectId: number): Observable<AttachmentsAndFoldersModel[]> {
    return this.http.get<AttachmentsAndFoldersModel[]>(`${ATTACHMENT_API}/find-all/${projectId}`);
  }

  uploadAttachment(attachment: any) { //: Observable<AttachmentsModel>
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    return this.http.post<AttachmentsModel>(`${ATTACHMENT_UPLOAD_API}?entityId=${attachment.entityId}&entityName=${attachment.entityName}${this.queryParams}`, formData)
      .pipe(retry(3));
  }
  
  uploadSupplierAttachment(attachment: any, type: "PRI" | "SEC") { 
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    const headers = new HttpHeaders();
    const req = new HttpRequest('POST', `${ATTACHMENT_UPLOAD_SUPPLIERS}?entityId=${attachment.entityId}&entityName=${attachment.entityName}&type=${type}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    );

    return this.http.request(req).pipe(retry(3))
  }

  deleteSupplierAttachment(supContractorFileId: number) {
    return this.http.delete<string>(`${ATTACHMENT_DELETE_SUPPLIERS}/${supContractorFileId}`);
  }

  uploadAttachmentsWithProgress(attachment: any) {
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    const req = new HttpRequest('POST', `${ATTACHMENT_UPLOAD_API}?entityId=${attachment.entityId}&entityName=${attachment.entityName}${this.queryParams}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    );

    return this.http.request(req).pipe(retry(3))
  }

  uploadAttachmentMandadoryDock(attachment: any) { //: Observable<AttachmentsModel>
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    const req = new HttpRequest('POST', `${ATTACHMENT_UPLOAD_MANDADORY_DOC}?entityId=${attachment.entityId}&entityName=${attachment.entityName}&idMandatoryDoc=${attachment.idMandatoryDoc || null}${this.queryParams}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    )

    return this.http.request(req).pipe(retry(10));
  }

  deleteAttachment(id?:string ): Observable<string> {
    return this.http.delete<string>(`${ATTACHMENT_API}/${id}`)
  }

  deleteEntityAttachment(entityId:string, entityName:string): Observable<string> {
    return this.http.delete<string>(`${ATTACHMENT_API}/${entityId}/${entityName}`)
  }

  updateComment(attachmentId: number, commentRequest: CommentRequestDto): Observable<AttachmentsModel> {

    return this.http.patch<AttachmentsModel>(`${ATTACHMENT_API}/${attachmentId}/comments`, commentRequest);
  }

  downloadFile(data: Blob, name: string) {
    const blobs = new Blob([data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blobs);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  getDownloadFile(idfile: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(
      `${ATTACHMENT_API}/download/${idfile}`,
      options
    );
  }

  getDownloadFileWithProgress(fileId: number, options: any = {}): Observable<any> {
    const url = `${ATTACHMENT_API}/download/${fileId}`;
    return this.http.get(url, { ...options, responseType: 'blob' });
  }

  getAttachmentsByEntityIdEntityNameAndContextInfo(entityId: number | undefined,
                                                   entityName: string,
                                                   contextInfo: string): Observable<AttachmentsModel[]> {
    return this.http.get<AttachmentsModel[]>(`${ATTACHMENT_API}/${entityId}/${entityName}?contextInfo=${contextInfo}`)
  }

  sliceAttachment(value: string, windowResolution: number): string {
    if (windowResolution >= 1920) {
      return value.length > 80 ? value.slice(0, 80) + '...' : value;
    } else {
      return value.length > 50 ? value.slice(0, 50) + '...' : value;
    }
  }

  //UPLOAD ATTACHMENTS PARA ANEXOS CONSULTORIAS
  uploadAttachmentsProposalsWithProgress(attachment: any) {
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    const req = new HttpRequest('POST', `${ATTACHMENT_UPLOAD_API_TECHNICAL_PROPOSALS}?entityId=${attachment.entityId}&entityName=${attachment.entityName}&idConfiguration=${attachment.idConfiguration}&idConsultancy=${attachment.idConsultancy}${this.queryParams}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    );

    return this.http.request(req).pipe(retry(3))
  }
}
