import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { DashboardsEnum } from './../../../shared/enum/dashboards.enum';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-dashboards',
  templateUrl: './menu-dashboards.component.html',
  styleUrls: ['./menu-dashboards.component.scss'],
})
export class MenuDashboardsComponent {

  @Output() public selectedTypeDisplay = new EventEmitter();

  subCurrent = 'dasboards';
  @Input() currentPage: string;


  private routeMappings = {
    [DashboardsEnum.LIC]: RoutesEnum.DASHBOARDS_TYPE_LICENSING,
    [DashboardsEnum.EIA]: RoutesEnum.DASHBOARDS_TYPE_STUDIES,
    [DashboardsEnum.MAN]: RoutesEnum.DASHBOARDS_TYPE_MANAGEMENT,
    [DashboardsEnum.SUP]: RoutesEnum.DASHBOARDS_TYPE_SUPPLIERS,
    [DashboardsEnum.RIPLA]: RoutesEnum.DASHBOARDS_TYPE_RIPLA,
    [DashboardsEnum.NEGOCIOS]: RoutesEnum.DASHBOARDS_TYPE_NEGOCIOS
  };

  constructor(
    private route: Router
  ) { }


  setCurrentPage(selectedOptionDisplay: string) {
    this.currentPage = selectedOptionDisplay;
    this.selectedTypeDisplay.emit(selectedOptionDisplay);

    const routendashboards = buildRoute(RoutesEnum.DASHBOARDS_TYPE, {
      type: this.routeMappings[selectedOptionDisplay]
    });

    if (routendashboards) {
      this.route.navigate([`${routendashboards}`]);
    }

  }

}
