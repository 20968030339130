
import { environment } from "../../../../environments/environment";
export const baseUrl = environment.apiUrl
export const DEMAND_TYPES_API = `${baseUrl}/demand-type`
export const DEMAND_API = `${baseUrl}/demands`
export const DEMAND_PHASES_API = `${baseUrl}/demand-phases`
export const PROJECT_SUSPENSION_API = `${baseUrl}/suspensions-project`
export const DEMAND_ACTION_PHASE_API = `${DEMAND_API}/action-phase`
export const DISCIPLINE_ASSESSMENT_BASE_API = `${baseUrl}/discipline-assessment`
export const DISCIPLINE_ASSESSMENT_API = `${DISCIPLINE_ASSESSMENT_BASE_API}/update-status/3`
export const
  DISCIPLINE_ASSESSMENT_LIST = `${DISCIPLINE_ASSESSMENT_BASE_API}/disciplines`
export const DISCIPLINE_ASSESSMENT_STATUS_API = `${DISCIPLINE_ASSESSMENT_BASE_API}/disciplines`
export const DISCIPLINE_ASSESSMENT_SEND_REVISION_API = `${DISCIPLINE_ASSESSMENT_BASE_API}/send-revision`
export const DISCIPLINE_ASSESSMENT_CATEGORIES = `${DISCIPLINE_ASSESSMENT_BASE_API}/categories`
export const DISCIPLINE_ASSESSMENT_STUDY_TYPE = `${DISCIPLINE_ASSESSMENT_BASE_API}/study-types`
export const DISCIPLINES_API = `${baseUrl}/disciplines`
export const ALL_DISCIPLINES_API = `${baseUrl}/disciplines/all`
export const DISCIPLINES_TO_SCOPE_CHANGE_API = `${DISCIPLINES_API}/to-scope-change`
export const FUNCTIONS_API = `${baseUrl}/functions`;
export const ENTITIES = `${baseUrl}/entities`;
export const ATTACHMENT_API = `${baseUrl}/attachments`;
export const NOTIFICATIONS_API = `${baseUrl}/notifications`;
export const SAVE_NOTIFICATIONS_API = `${baseUrl}/notifications/save`;
export const ATTACHMENT_UPLOAD_API = `${baseUrl}/attachments/upload`;
export const ATTACHMENT_UPLOAD_SUPPLIERS = `${baseUrl}/sup-material/upload`;
export const ATTACHMENT_DELETE_SUPPLIERS = `${baseUrl}/sup-material`;
export const ATTACHMENT_UPLOAD_API_TECHNICAL_PROPOSALS = `${baseUrl}/sup-configuration/tech-prop/upload`;
export const ATTACHMENT_UPLOAD_MANDADORY_DOC = `${baseUrl}/mandatory-doc/upload`;
export const ATTACHMENT_MANDADORY_DOC = `${baseUrl}/mandatory-doc/attachments`;
export const SCOPES_API = `${baseUrl}/scopes`
export const USERS_API = `${baseUrl}/users`
export const USERS_DISCIPLINES_API = `${USERS_API}/discipline`
export const USERS_ROLE_API = `${baseUrl}/user-roles`
export const PROJECT_API = `${baseUrl}/projects`
export const TOPOLOGIES_API = `${baseUrl}/topologies`
export const LOCATION_STATES_API = `${baseUrl}/locations/states`
export const LOCATION_CITIES_API = `${baseUrl}/locations/cities`

export const SUB_PROJECT_REVIEWS_CANCEL = `${baseUrl}/subproject-reviews/cancel-subproject-review`
export const REGION_API = `${baseUrl}/regions`
export const COMPLEX_REGION_API = `${baseUrl}/complexes/region`
export const COMPLEX_API = `${baseUrl}/complexes`
export const PROJECT_PHASE_API = `${baseUrl}/project-phases`
export const PROJECT_PHASE_UPDATE_API = `${PROJECT_PHASE_API}/update-status`
export const PROJECT_PHASE_SEND_REVISION_API = `${PROJECT_PHASE_API}/send-revision`
export const NLP_PRODUCT_API = `${baseUrl}/product-nlp`
export const NLP_TEMPORARY_PROJECT_LIST_API = `${NLP_PRODUCT_API}/list`
export const NLP_TERM_REFERENCE_API = `${baseUrl}/doc-reference`
export const NLP_TERM_REFERENCE_LIST_API = `${NLP_TERM_REFERENCE_API}/list`
export const NLP_MODAL_NEW_SECTION_DOC_REFERENCE = `${baseUrl}/doc-reference`
export const NLP_MODAL_NEW_SECTION_DOC_SECTION = `${baseUrl}/doc-section`
export const NLP_SECTION_ANALYSIS_API = `${baseUrl}/section-analysis`
export const NLP_SELECT_TERM_REFERENCE_API = `${NLP_TERM_REFERENCE_API}/copy`
export const NLP_RESULT_PROJECT_TEMP_API = `${baseUrl}/nlp-result/project-temp`
export const NLP_MESSAGE_PUBLISH_API = `${baseUrl}/message/publish`
export const PRODUCT_DEMAND = `${baseUrl}/product-demand`
export const PRODUCT_NLP_ATTACHAMENTS_URLS = `${baseUrl}/product-nlp/attachment-urls`;
export const NLP_DOCUMENT_SECTION_REF = `${baseUrl}/doc-reference/url`;
export const NLP_QUEUE_ANALYSIS = `${baseUrl}/message/publish`;
export const DOCUMENT_VERSION_RESTORE = `${baseUrl}/folder/restore`;
export const LICENSING_TYPES_LIST = `${baseUrl}/licenses`;
export const LICENSING_SAVE_EDIT = `${baseUrl}/projects-licensing`;
export const NLP_RESULT_STATUS = `${baseUrl}/nlp-result/by-product-nlp-id`;
export const TASK_CREATE = `${baseUrl}/task`;
export const TASK_LIST = `${baseUrl}/task`;
export const ROLES_CONFIG = `${baseUrl}/roles/config-roles`;
export const DASHBOARD_API = `${baseUrl}/dashboard`;
export const LOGOUT_API = `${baseUrl}/token/logout`;
export const PROJECTS_V2 = `${baseUrl}/subprojects`;
export const PROJECTS_V2_SEND_ANALISYS = `${baseUrl}/subprojects/send-analysis`;
export const LICENSES_V2_FINAL_ANALYSIS = `${baseUrl}/subprojects/final-analysis`;
export const LICENSES_V2_FINAL_OPINION = `${baseUrl}/subprojects/final-opinion`;
export const DELETE_DRAFT = `${baseUrl}/subprojects/delete-rascunho`;
export const LICENSES_V2 = `${baseUrl}/projects-licensing/by-project`;
export const LICENSES_V2_BY_COMPETENT_ORGAN = `${baseUrl}/licenses/competent-organ`;
export const LICENSES_V2_INITIAL_LICENSE_STRATEGY = `${baseUrl}/initial-license-strategy`;
export const LICENSES_V2_ENVIROMENTAL_STUDIES = `${baseUrl}/enviromental-study`;
export const RESTRICTIONS = `${baseUrl}/restrictions`;
export const REQUESTING_AREA = `${baseUrl}/areas`;
export const OPERATIONAL_UNITS = `${baseUrl}/operational-units/complex`;
export const MANDATORY_DOCUMENTS = `${baseUrl}/mandatory-doc`;
export const LOGS_API = `${baseUrl}/logs`;
export const ENVIRONMENTAL_STUDIES = `${baseUrl}/enviromental-study`;
export const ACTIVITY_DELIVERABLE_DASHBOARD = `${baseUrl}/activity-deliverables/dashboard`;

// ====> ** process/licenses */
export const PROCESS_BY_ID = `${baseUrl}/projects-licensing`;
export const PROCESS_BY_PROJECT_ID = `${baseUrl}/projects-licensing/by-project`;
export const PROCESS_DELETE =  `${baseUrl}/projects-licensing/delete`;
export const LICENSES_LIST_BY_ORGAN = `${baseUrl}/licenses/competent-organ`;
export const LINKED_PROCESS_DELETE = `${baseUrl}/projects-licensing/update-link`;
export const CREATE_ACTIVITY = `${baseUrl}/project-licensing/activities`;
export const ACTIVITIES_TITLES_LIST = `${baseUrl}/project-licensing/activities/titles`;
export const DELETE_ACTIVITY = `${baseUrl}/project-licensing/activities`;
export const ACTIVITIES_BY_ID = `${baseUrl}/project-licensing/activities`;
export const EIA_ACTIVITIES_BY_ID = `${baseUrl}/project-licensing/activities/eia`;
export const ACTIVITIES_UPDATE_BY_ID = `${baseUrl}/project-licensing/activities`;
export const SUB_ACTIVITIES_LIST = `${baseUrl}/project-licensing/activities/list`;
export const SUMARIZE_ACTIVITY = `${baseUrl}/project-licensing/activities/sumarize-activity`;



// ====> ** RIPPLA /revisao */
export const UPDATE_SUB_PROJECT_REVIEWS = `${baseUrl}/subproject-reviews/complete`;
export const FINAL_ANALYSIS = `${baseUrl}/subprojects/final-analysis`;
export const SUB_PROJECT_REVIEWS = `${baseUrl}/subproject-reviews`;
export const SUB_PROJECT_REVIEW_BY_ID = `${baseUrl}/subprojects/final-opinion/review`;
export const UPDATE_SUB_PROJECT_REVIEWS_ME = `${baseUrl}/subproject-reviews/scope-change`;
export const EDIT_SUB_PROJECT_REVIEWS = `${SUB_PROJECT_REVIEWS}/edit-subproject-review`;
export const COMMUNICATION_TYPES_API = `${baseUrl}/communication-type`;
export const ACTIVITIES_BY_PROJECT_LICENSE_ID_API = `${baseUrl}/project-licensing/activities/list`;
export const SUB_PROJECT_CANCEL = `${baseUrl}/subprojects/cancellation`;

export const COMMUNICATIONS_API = `${baseUrl}/communications`;

// ====> ** scope-change */
export const SCOPE_CHANGE_BY_ID = `${baseUrl}/scope-changes-project`;

// ====> ** fornecedores */
export const CONSULTANCY_API = `${baseUrl}/consultancies`;
export const CONSULTANCY_USERS_API = `${baseUrl}/consultancy-responsibles?consultancyId`;
export const CONSULTANCY_CONFIG__SUPPLIER_RELEASED = `${baseUrl}/config-suppliers/is-study-released`;
export const CONFIGURATION_STUDY_NEW = `${baseUrl}/config-suppliers`;
export const CONFIGURATION_PROFESSIONAL_STUDY_NEW = `${baseUrl}/config-suppliers/add-professional`;
export const CONFIGURATION_STUDY = `${baseUrl}/config-suppliers/by-activity`;
export const GET_TEAM_VALE = `${baseUrl}/sup-configuration/review-team`;
export const GET_TEAM_CONSULTANCY = `${baseUrl}/suppliers/studies/team`;
export const REMOVE_PROFESSIONAL_API = `${baseUrl}/config-suppliers/remove-professional`;
export const GET_ACTIVITIES_DELIVERABLES = `${baseUrl}/activity-deliverables`;
export const GET_MY_STUDIES_CONSULTANCY = `${baseUrl}/suppliers/studies/my-studies`;

// ====> ** chat */
export const CHAT_AI = `${baseUrl}/aichat`;
export const CHAT_AI_EMAIL = `${CHAT_AI}/send-email`;
export const GET_ALL_CHATS = `${CHAT_AI}/all-chats`
export const SUBPROJECT_DEAD_LINE_LOGS = `${baseUrl}/analysis-deadline-logs`
